//
//
//
//
//
//
//
//
//
//
//

import $ from "jquery";

export default {
  name: 'Contacts',
  props: {
    email: String,
  },
  methods: {
    showMailModal() {
      $('#modalAddMail').modal('show');
    }
  }
}
